import $ from 'jquery'; // Importa jQuery
$(".AlertDown").click(function(){
    setTimeout(function(){
      $("#alertsContainer").fadeOut(200);
    }, 500);
  });

$(".AlertDown").click(function(){
  setTimeout(function(){ 
    $("#myAlertsContainer").fadeOut(200);
  }, 500);
});

setTimeout(fade_out, 2500);
function fade_out() {
  $("#mySuccessAlert").fadeOut(200);
}

window.addEventListener('load', function() {
    var forms = document.querySelectorAll('form');
    forms.forEach(function(form) {
        form.reset();
    });
});

document.addEventListener("DOMContentLoaded", function() {
    function disableButton(event) {
        const button = event.target;
        if (button.id === 'loadingButton') {
            // Disabilita il pulsante dopo il click o submit
            setTimeout(() => {
                button.disabled = true;
            }, 0);
        }
    }

    function addListeners() {
        const buttons = document.querySelectorAll('#loadingButton');
        buttons.forEach(button => {
            button.addEventListener('click', disableButton);
            button.addEventListener('submit', disableButton);
        });
    }

    // Aggiungi gli event listener iniziali
    addListeners();

    // MutationObserver per gestire i nuovi elementi aggiunti dinamicamente
    const observer = new MutationObserver(addListeners);
    observer.observe(document.body, { childList: true, subtree: true });
});

// document.addEventListener("DOMContentLoaded", function() {
//     // Seleziona tutti i pulsanti di tipo submit
//     const submitButtons = document.querySelectorAll('input[type="submit"], button[type="submit"]');
    
//     // Aggiungi un event listener a ciascun pulsante
//     submitButtons.forEach(function(button) {
//         button.addEventListener('click', function(event) {
//             // Disabilita il pulsante per prevenire ulteriori click
//             button.disabled = true;
//         });
//     });
// });


document.addEventListener('DOMContentLoaded', function() {
    const themeToggle = document.getElementById('theme-toggle');
    const themeToggleMobile = document.getElementById('theme-toggle-mobile');
    const body = document.body;
    const logoImage = document.getElementById('logo-image');

    const logoCreate = document.getElementById('logo-create');
    const logoHome = document.getElementById('logo-home');
    const logoPending = document.getElementById('logo-pending');
    const logoRisk = document.getElementById('logo-risk');
    const logoStakeholder = document.getElementById('logo-stakeholder');

    const logoCreateRev = document.getElementById('logo-create-rev');
    const logoHomeRev = document.getElementById('logo-home-rev');
    const logoPendingRev = document.getElementById('logo-pending-rev');
    const logoRiskRev = document.getElementById('logo-risk-rev');
    const logoStakeholderRev = document.getElementById('logo-stakeholder-rev');

    // Funzione per salvare la preferenza del tema in sessione
    function saveThemePreference(theme) {
        // Invia la preferenza del tema al server Laravel tramite AJAX
        fetch('/set-theme', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: JSON.stringify({ theme: theme })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to save theme preference');
            }
        })
        .catch(error => {
            console.error(error);
        });
    }

    // Aggiungi un gestore di eventi al selettore del tema
    themeToggle.addEventListener('click', function() {
        if (body.classList.contains('light-theme')) {
            document.getElementById('mobile-navbar').classList.remove('light-theme');
            document.getElementById('mobile-navbar').classList.add('dark-theme');
            body.classList.remove('light-theme');
            body.classList.add('dark-theme');
            themeToggle.classList.remove('light-theme');
            themeToggle.classList.add('dark-theme');
            if (logoImage) {
            logoImage.src = "data:image/png;base64, " + loginLogoDark;
            }

            if (logoCreate) {
            logoCreate.src = "data:image/png;base64, " + createLogoDark;
            }
            if (logoHome) {
            logoHome.src = "data:image/png;base64, " + homeLogoDark;
            }
            if (logoPending) {
            logoPending.src = "data:image/png;base64, " + pendingLogoDark;
            }
            if (logoRisk) {
            logoRisk.src = "data:image/png;base64, " + riskLogoDark;
            }
            if (logoStakeholder) {
            logoStakeholder.src = "data:image/png;base64, " + stakeholderLogoDark;
            }

            if (logoCreateRev) {
            logoCreateRev.src = "data:image/png;base64, " + createLogoLight;
            }
            if (logoHomeRev) {
            logoHomeRev.src = "data:image/png;base64, " + homeLogoLight;
            }
            if (logoPendingRev) {
            logoPendingRev.src = "data:image/png;base64, " + pendingLogoLight;
            }
            if (logoRiskRev) {
            logoRiskRev.src = "data:image/png;base64, " + riskLogoLight;
            }
            if (logoStakeholderRev) {
            logoStakeholderRev.src = "data:image/png;base64, " + stakeholderLogoLight;
            }

            saveThemePreference('dark');
        } else {
            document.getElementById('mobile-navbar').classList.remove('dark-theme');
            document.getElementById('mobile-navbar').classList.add('light-theme');
            body.classList.remove('dark-theme');
            body.classList.add('light-theme');
            themeToggle.classList.remove('dark-theme');
            themeToggle.classList.add('light-theme');
            if (logoImage) {
            logoImage.src = "data:image/png;base64, " + loginLogoLight;
            }


            if (logoCreate) {
            logoCreate.src = "data:image/png;base64, " + createLogoLight;
            }
            if (logoHome) {
            logoHome.src = "data:image/png;base64, " + homeLogoLight;
            }
            if (logoPending) {
            logoPending.src = "data:image/png;base64, " + pendingLogoLight;
            }
            if (logoRisk) {
            logoRisk.src = "data:image/png;base64, " + riskLogoLight;
            }
            if (logoStakeholder) {
            logoStakeholder.src = "data:image/png;base64, " + stakeholderLogoLight;
            }

            if (logoCreateRev) {
            logoCreateRev.src = "data:image/png;base64, " + createLogoDark;
            }
            if (logoHomeRev) {
            logoHomeRev.src = "data:image/png;base64, " + homeLogoDark;
            }
            if (logoPendingRev) {
            logoPendingRev.src = "data:image/png;base64, " + pendingLogoDark;
            }
            if (logoRiskRev) {
            logoRiskRev.src = "data:image/png;base64, " + riskLogoDark;
            }
            if (logoStakeholderRev) {
            logoStakeholderRev.src = "data:image/png;base64, " + stakeholderLogoDark;
            }

            saveThemePreference('light');
        }
    });
    themeToggleMobile.addEventListener('click', function() {
        if (body.classList.contains('light-theme')) {
            document.getElementById('mobile-navbar').classList.remove('light-theme');
            document.getElementById('mobile-navbar').classList.add('dark-theme');
            body.classList.remove('light-theme');
            body.classList.add('dark-theme');
            themeToggleMobile.classList.remove('light-theme');
            themeToggleMobile.classList.add('dark-theme');
            saveThemePreference('dark');
        } else {
            document.getElementById('mobile-navbar').classList.remove('dark-theme');
            document.getElementById('mobile-navbar').classList.add('light-theme');
            body.classList.remove('dark-theme');
            body.classList.add('light-theme');
            themeToggleMobile.classList.remove('dark-theme');
            themeToggleMobile.classList.add('light-theme');
            saveThemePreference('light');
        }
    });


    // const mobileNavbar = document.getElementById('mobile-navbar');
    // const mobileToggle = document.getElementById('mobile-toggle');

    // // Aggiunge un evento di click al toggle per la navbar mobile
    // mobileToggle.addEventListener('click', function() {
    //   mobileNavbar.classList.toggle('open');
    // });
});
