import $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-autofill-dt';

var dataTable;
var lastWindowWidthRisk = $(window).width();
var initializationWindowWidthRisk = false;
$(document).ready(function() {
    dataTable = $('#riskTable').DataTable({
    autofill: true,
    scrollY: '461px',
    scrollCollapse: true,
    scrollX: true,
    lengthMenu: [
    [20, 50, 100, -1],
    [20, 50, 100, 'TUTTI'],
    ],
    language: {
        search: "Cerca",
        lengthMenu: 'Visualizza _MENU_ Rischi',
        zeroRecords: 'La ricerca non ha portato alcun risultato.',
        info: 'Pagina _PAGE_ di _PAGES_',
        infoEmpty: 'Nessun Rischio disponibile',
        infoFiltered: '(filtrata da _MAX_ Rischi totali)',
        loadingRecords: "Caricamento..",
        paginate: {
            first:    "Primo",
            last:    "Ultimo",
            next:    "Successivo",
            previous: "Precedente"
        },
    },  
    drawCallback: function() {
        $('#visibilityTable').removeClass('invisible');      
        }
    });
    setInterval(function() {
        var currentWindowWidth = $(window).width();
        if (currentWindowWidth !== lastWindowWidthRisk) {
            lastWindowWidthRisk = currentWindowWidth;
            if (initializationWindowWidthRisk == true) {
                dataTable.draw();
            }else{
                initializationWindowWidthRisk = true;
            }
        }
    }, 500);
});
// $('#riskTable').on('mouseenter', 'td', function() {
//     var td = $(this);
//     if (!td.hasClass("dataTables_empty")) {
//         var id = td.attr('id');
//         var parts = id.split('_');
        
//         if (td.hasClass('esterno')) {
//             if (isEllipsisActive(td[0])) {
//                 handleHover(id, 'full_width_2', parts[0]); 
//             }
//         } else {
//             if (isEllipsisActive(td[0])) {
//                 handleHover(id, 'full_width', parts[0]); 
//             }
//         }
//     }
// }).on('mouseleave', 'td', function() {
//     var td = $(this);
//     if (!td.hasClass("dataTables_empty")) {
//         var id = td.attr('id');
//         var parts = id.split('_');
        
//         if (td.hasClass('esterno')) {
//             handleHover(id, parts[0], 'full_width_2');
//         } else {
//             handleHover(id, parts[0], 'full_width');
//         }
//     }
// });

// function handleHover(id, addClass, removeClass) {
//     $('#' + id).addClass(addClass).removeClass(removeClass);
// }

// function isEllipsisActive(element) {
//     var style = window.getComputedStyle(element, null);
//     return style.getPropertyValue('overflow') === 'hidden' &&
//            style.getPropertyValue('text-overflow') === 'ellipsis' &&
//            (element.scrollWidth > element.clientWidth);
// }